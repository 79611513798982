import React, { useState } from "react";
import { useForm } from "react-hook-form";

import AuthorizationTemplate from "../../../templates/AuthorizationTemplate";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";

export default function Recovery() {
  const [step, setStep] = useState(1);
  const [codeError, setCodeError] = useState(null);

  const recoveryForm = useForm();

  let actualStep = null;
  if (step === 1)
    actualStep = <Step1 setStep={setStep} recoveryForm={recoveryForm} />;
  else if (step === 2)
    actualStep = (
      <Step2
        setStep={setStep}
        recoveryForm={recoveryForm}
        codeError={codeError}
      />
    );
  else if (step === 3)
    actualStep = (
      <Step3
        setStep={setStep}
        recoveryForm={recoveryForm}
        setCodeError={setCodeError}
      />
    );

  return (
    <AuthorizationTemplate
      title={`WIKĘD - odzyskiwanie hasła ${step} z 3`}
      step={step}
    >
      {actualStep}
    </AuthorizationTemplate>
  );
}
