import React, { useMemo } from "react";
import styled from "styled-components";
import FileDetails from "../../pages/library/FileDetails";
import TopTab from "./TopTab";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { routes } from "../../routes";
import FilesButton from "../FilesButton";
import usePermissionObject from "../../hooks/usePermissionObject";
import FolderButton from "../FolderButton";
import {
  createRootFile,
  deleteRootFile,
} from "../../redux/actions/rootfileActions";
import { useConfirmModalDispatch } from "../../context/ModalContext";
import config from "../../aws-exports";
import { Storage } from "aws-amplify";
import { usePermissionState } from "../../context/permission";
const {
  aws_user_files_s3_bucket_region: region,
  aws_user_files_s3_bucket: bucket,
  aws_cognito_identity_pool_id: poolId,
} = config;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  @media only screen and (max-width: 600px) {
    justify-content: flex-start;
  }
`;

const TabsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 600px) {
    width: 30px;
  }
`;

const TabsPanel = ({ addText, parentId }) => {
  const history = useHistory();
  const permissionObject = usePermissionObject("library");
  const modalDispatch = useConfirmModalDispatch();
  const dispatch = useDispatch();

  function navigateTo() {
    history.push(routes.library.upload);
  }

  const tabs = useMemo(
    () => [
      {
        id: 1,
        title: "WIKĘD",
        route: routes.library.home,
        content: FileDetails,
      },
    ],
    []
  );

  const basicUrl = `https://${bucket}.s3.${region}.amazonaws.com/public/`;
  const createFolderAWS = (folderPath) => {
    Storage.put(folderPath, {}).then((r) => console.log(r));
  };

  const addFolder = () => {
    modalDispatch({
      type: "OPEN",
      payload: {
        title: "Nowy Folder",
        content: "Podaj nazwę folderu",
        isInput: true,
        buttonText: "Dodaj",
        action: (input) => {
          const folderPath = `${parentId}/${input}/`;
          const file = {
            ref: basicUrl + folderPath,
            parentId: parentId,
            name: input,
            isFolder: true,
          };
          dispatch(createRootFile(file));
          createFolderAWS(folderPath);
        },
      },
    });
  };

  return (
    <Container>
      <TabsWrapper>
        {tabs.map(({ title, route, id }) => (
          <TopTab title={title} key={id} to={route} activeClassName="active" />
        ))}
      </TabsWrapper>
      {permissionObject.create && (
        <ButtonsWrapper>
          <FolderButton
            text={"Nowy Folder"}
            width={150}
            height={42}
            onClick={() => addFolder()}
          />
          <FilesButton
            text={addText}
            width={166}
            height={42}
            onClick={() => navigateTo()}
          />
        </ButtonsWrapper>
      )}
    </Container>
  );
};

export default TabsPanel;
